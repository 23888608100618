@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #191919;
  font-family: "Lato", "Lucida Grande", "Lucida Sans Unicode", Tahoma, Sans-Serif;
  font-size: 19.5px;
  margin: 0;
}

header {
  height: 64px;
  font-size: large;
  text-align: center;
  background-color: #000;
  color: #fff;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
header a {
  background-color: #000;
  color: #fff;
  text-decoration: underline;
  padding: 0 3px 0 3px;
}

header a:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
}

footer {
  font-size: smaller;
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 5px;
  margin-top: 30px;
}

footer a {
  background-color: #000;
  color: #fff;
  text-decoration: underline;
  padding: 0 3px 0 3px;
}

footer a:hover {
  background-color: #fff;
  color: #000;
  text-decoration: none;
}

#wheelDiv {
  height: 600px;
  width: 600px;
  margin: 0 auto auto;
}

#wheelDiv>svg {
  height: 100%;
  width: 100%;
}

@media (max-width: 600px) {
  #wheelDiv {
    width: 100%;
    height: 100%;
  }
}

.triangle-topright {
  width: 0;
  height: 0;
  border-top: 39px solid black;
  border-left: 39px solid transparent;
}


.triangle-bottomright {
  width: 0;
  height: 0;
  border-bottom: 39px solid black;
  border-left: 39px solid transparent;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1E1D1D;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3B3939;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

